import React, { ReactElement } from "react";
import FooterLogo from "./footerLogo";
// import FacebookIcon from "./icons/facebookIcon";
// import InstagramIcon from "./icons/instagramIcon";
// import TwitterIcon from "./icons/twitterIcon";
import LinkedinIcon from "./icons/linkedinIcon";
import YoutubeIcon from "./icons/youtubeIcon";
import { motion } from "framer-motion";
import { DomainInfo } from "../utils/services";
import styles from "./style.module.scss";
const socialLinks = [
    // {
    //     name: "facebook",
    //     url: "https://web.facebook.com/Officialseveralbrands",
    //     icon: <FacebookIcon />,
    // },
    // {
    //     name: "twitter",
    //     url: "https://twitter.com/Several_brands",

    //     icon: <TwitterIcon />,
    // },
    // {
    //     name: "instagram",
    //     url: "https://www.instagram.com/official_severalbrands/",
    //     icon: <InstagramIcon />,
    // },
    {
        name: "linkedin",
        url: "https://www.linkedin.com/company/severalbrands/",
        icon: <LinkedinIcon />,
    },
    {
        name: "youtube",
        url: "https://www.youtube.com/channel/UCoFW0ei4CeVIq0guUvvclgA",
        icon: <YoutubeIcon />,
    },
];

export default function SeveralBrandsFooter({
    domain,
    contentListExist = false,
}: {
    domain: DomainInfo;
    contentListExist?: boolean;
}): ReactElement {
    const footerLinksCol1 = domain?.menus
        ? domain?.menus?.find(
              (menu) => menu.slug === "severalbrands-footer-col-1",
          )?.links
        : domain?.menusList?.find(
              (menu) => menu.slug === "severalbrands-footer-col-1",
          )?.items;
    const footerLinksCol2 = domain?.menus
        ? domain?.menus?.find(
              (menu) => menu.slug === "severalbrands-footer-col-2",
          )?.links
        : domain?.menusList?.find(
              (menu) => menu.slug === "severalbrands-footer-col-2",
          )?.items;

    return (
        <footer className={`${styles["bgStyle"]} `}>
            <div
                className={`  flex   flex-col  max-w-7xl  3xl:max-w-[120rem] mx-auto px-5 3xl:pt-36 pt-20 ${
                    contentListExist
                        ? "3xl:pb-24 pb-[100px]"
                        : "3xl:pb-24 pb-12"
                }   gap-12 lg:gap-6`}
            >
                <motion.div
                    transition={{ duration: 1.5 }}
                    initial={{ opacity: 0, y: 150 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true }}
                    className=" flex flex-col lg:flex-row  justify-between gap-5 lg:gap-0"
                >
                    <div>
                        <div className="mx-auto lg:mx-0  w-[206px] h-[31px] 3xl:w-[306px] 3xl:h-[61px] relative">
                            <FooterLogo />
                        </div>
                        <p className="text-center lg:text-left lg:ml-7 mt-3 lg:max-w-[500px] 3xl:max-w-[700px] 3xl:text-xl 3xl:leading-8 font-light text-sm lg:text-base">
                            Several Brands: Performance-Based Digital Marketing
                            Experts
                        </p>
                        <p className="lg:ml-7  mt-12 lg:mt-5 lg:max-w-[500px] 3xl:max-w-[700px] 3xl:text-xl 3xl:leading-8 font-light text-sm lg:text-base">
                            Several Brands, LLC, is a holding company that
                            manages various brands and provides
                            performance-based digital marketing services to
                            diverse customers.
                        </p>
                    </div>
                    <div
                        className={`lg:mt-5 flex flex-wrap  lg:justify-end lg:flex-nowrap   lg:gap-28 gap-8 text-sm   lg:mx-0`}
                    >
                        <div>
                            <ul>
                                {footerLinksCol1?.map((lnk, index) => (
                                    <li
                                        key={lnk.link}
                                        className="pb-1 text-sm lg:text-base 3xl:text-xl first-of-type:font-bold first-of-type:text-[#37367D] first-of-type:text-base first-of-type:lg:text-lg  first-of-type:3xl:text-2xl font-light first-of-type:mb-2"
                                    >
                                        {index === 0 ? (
                                            <p>{lnk?.title}</p>
                                        ) : (
                                            <a
                                                href={lnk?.link}
                                                className={`  
                                                inline-block mb-1 `}
                                            >
                                                {lnk?.title}
                                            </a>
                                        )}
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div>
                            <ul>
                                {footerLinksCol2?.map((lnk, index) => (
                                    <li
                                        key={lnk.link}
                                        className="pb-1 text-sm lg:text-base 3xl:text-xl  first-of-type:font-bold first-of-type:text-[#37367D] first-of-type:text-base first-of-type:lg:text-lg  first-of-type:3xl:text-2xl font-light first-of-type:mb-2"
                                    >
                                        {index === 0 ? (
                                            <p>{lnk?.title}</p>
                                        ) : (
                                            <a
                                                href={lnk?.link}
                                                className={`  
                                                inline-block mb-1 `}
                                            >
                                                {lnk?.title}
                                            </a>
                                        )}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </motion.div>
                <motion.div
                    transition={{ duration: 1.5 }}
                    initial={{ opacity: 0, y: 150 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true }}
                    className=" flex   lg:justify-between flex-col lg:flex-row gap-3 lg:gap-0"
                >
                    <div className=" lg:ml-7 flex gap-2">
                        {socialLinks?.map((lnk) => (
                            <a
                                href={lnk.url}
                                key={lnk.name}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <span className=" cursor-pointer  block  relative ">
                                    {lnk.icon}
                                </span>
                            </a>
                        ))}
                    </div>
                    <p className="3xl:text-xl 3xl:leading-8 font-light text-sm lg:text-base">
                        © 2024 Severalbrands.com. All Rights Reserved
                    </p>
                </motion.div>
            </div>
        </footer>
    );
}
